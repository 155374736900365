<template>
  <div v-if="false">
    <molecules-custom-dialog
      v-if="showSuspend && selectedItem"
      :show="showSuspend"
      title="Anda akan meng-suspend pekerja!"
      :caption="`${$toCapitalize(selectedItem.fullName)} akan tersuspend dari perusahaan anda!`"
      :items="[
        {
          title: 'Batalkan',
          full: false,
          exec: () => (showSuspend = false),
        },
        {
          title: 'Ya, Lanjutkan',
          override: 'red--text',
          full: false,
          exec: () => {
            // rest
            suspendWorker(reasonSuspend || 'Bermasalah')
          },
        },
      ]"
    >
      <v-text-field
        class="rounded-lg"
        placeholder="Alasan Suspend"
        append-icon="mdi-account-cancel"
        v-model="reasonSuspend"
        background-color="accent"
        hide-details
        solo
        flat
      />
    </molecules-custom-dialog>
    <molecules-custom-dialog
      v-if="showApprove"
      :show="showApprove"
      :title="showApprove.supervisorApproval == $status.approve ? 'Approve Pekerja' : 'Reject Pekerja' + '?'"
      :caption="`Anda akan meng-${showApprove.supervisorApproval == $status.approve ? 'approve' : 'reject'} pekerja (${
        showApprove.worker && showApprove.worker.fullName
      })`"
      :items="[
        {
          title: 'Batalkan',
          full: false,
          exec: () => (showApprove = null),
        },
        {
          title: showApprove.supervisorApproval == $status.approve ? 'Approve' : 'Reject',
          override: 'red--text',
          full: false,
          exec: () => {
            toggleApproval()
          },
        },
      ]"
    >
      <atoms-text-field color="background" label="Komentar Anda" :noLabel="true" v-model="comment"
    /></molecules-custom-dialog>
    <!-- filter -->
    <molecules-wrapper class="pa-0 text-center" id="filter">
      <atoms-text class="pb-2 primary--text"
        ><span :class="selectedItem && 'text-decoration-line-through'"
          >(Cari dan pilih pekerja anda untuk melihat detailnya)</span
        ></atoms-text
      >
      <v-form @submit.prevent="search">
        <v-row>
          <v-col cols="12" md="8">
            <atoms-text-field
              label="Cari Pekerja"
              v-model.trim="term"
              :noLabel="true"
              :appendAction="
                () => {
                  term = ''
                  loadData()
                }
              "
              appendIcon="mdi-close"
          /></v-col>
          <v-col>
            <atoms-button type="submit" style="width: 100%" class="primary lighten-5 primary--text"
              ><v-icon left>mdi-magnify</v-icon>Cari Pekerja</atoms-button
            >
          </v-col>
          <v-col>
            <atoms-button
              @click="
                () => {
                  term = ''
                  items = null
                  page = 1
                  search()
                }
              "
              class="red lighten-4 red--text"
              :style="{
                width: '100%',
              }"
              ><v-icon left>mdi-history</v-icon> Bersihkan Pencarian</atoms-button
            >
          </v-col>
        </v-row>
      </v-form>
    </molecules-wrapper>
    <div v-if="!selectedItem">
      <v-scroll-y-transition>
        <div>
          <div v-if="mainLoading" class="mt-8">
            <v-skeleton-loader
              v-for="i in 5"
              :key="i"
              type="list-item-avatar"
              :class="['accent', 'rounded-lg pa-4 mb-2']"
              :style="{
                'box-shadow': 'rgba(0, 0, 0, 0.05) 0px 0px 0px 1px',
              }"
            />
          </div>
          <div v-else>
            <div v-if="items && items.data && items.data.length > 0" class="mt-4">
              <atoms-text class="pa-2"
                ><v-icon>mdi-magnify</v-icon>Hasil Pencarian, {{ items.count }} data ditemukan</atoms-text
              >
              <div
                v-for="({ photo, fullName, ...rest }, i) in items.data || []"
                :key="i"
                :class="['accent', 'rounded-lg pa-4 mb-2']"
                :style="{
                  'box-shadow': 'rgba(0, 0, 0, 0.05) 0px 0px 0px 1px',
                }"
              >
                <v-row align="center">
                  <v-col class="shrink">
                    <v-avatar class="rounded-lg">
                      <atoms-image v-if="photo" class="rounded-lg" :enableZoom="true" :src="photo" />
                      <v-icon v-else>mdi-account</v-icon></v-avatar
                    >
                  </v-col>
                  <v-col cols="8" md="4">
                    <atoms-text class="font-weight-bold">{{ $toCapitalize(fullName || 'Pekerja') }}</atoms-text>
                  </v-col>
                  <v-col cols="8" md="4">
                    <atoms-text class="primary--text"
                      ><v-icon left class="primary--text">mdi-history</v-icon
                      >{{ rest.absensi || 0 }} Absensi</atoms-text
                    >
                    <atoms-text
                      :class="
                        rest.blacklist ||
                        rest.resigned ||
                        (rest.clientSuspends && rest.clientSuspends.length > 0 && 'red--text')
                      "
                      >Status:
                      {{
                        (rest.blacklist && 'Blacklisted') ||
                        (rest.resigned && 'Resign') ||
                        (rest.clientSuspends && rest.clientSuspends.length > 0 && 'Suspend') ||
                        'Aktif'
                      }}</atoms-text
                    >
                  </v-col>
                  <v-col align="end">
                    <atoms-button
                      @click="
                        () => {
                          selectItem({ photo, fullName, ...rest })
                        }
                      "
                      class="primary--text"
                    >
                      <v-icon left>mdi-magnify</v-icon> Pilih &amp; Lihat</atoms-button
                    >
                  </v-col>
                </v-row>
              </div>
              <v-row justify="center">
                <v-col cols="8">
                  <v-container class="max-width">
                    <v-pagination
                      class="my-4"
                      v-model="page"
                      :length="items.count ? Math.ceil(items.count / limit) : 0"
                    ></v-pagination>
                  </v-container>
                </v-col>
              </v-row>
            </div>
            <molecules-wrapper v-else class="d-flex justify-center align-center my-8 py-8 outlined">
              <div class="text-center">
                <atoms-image :width="250" src="/assets/missing.svg" />
                <atoms-title :h3="true" class="mt-6">Anda Belum Mencari dan Memilih Pekerja</atoms-title>
              </div>
            </molecules-wrapper>
          </div>
        </div>
      </v-scroll-y-transition>
    </div>
    <div v-else class="mt-8 accent rounded-lg">
      <v-scroll-y-transition>
        <v-row class="py-8 px-4">
          <v-col align="center" cols="12" md="4">
            <v-row justify="center">
              <v-col cols="10" class="py-0">
                <v-avatar color="grey lighten-3" size="100" class="rounded-lg">
                  <atoms-image v-if="selectedItem.photo" :width="100" :enableZoom="true" :src="selectedItem.photo" />
                  <v-icon v-else light>mdi-account</v-icon>
                </v-avatar>
              </v-col>
              <v-col cols="10">
                <atoms-title>{{ $toCapitalize(selectedItem.fullName) }}</atoms-title>
                <atoms-text v-if="client" class="px-4">{{ client.name }}</atoms-text>
                <atoms-text v-if="selectedItem.detail && selectedItem.detail.prefJobs" :span="true" class="px-4">{{
                  selectedItem.detail.prefJobs.filter((x) => x.length > 0).toString()
                }}</atoms-text>
                <br />
                <v-chip
                  v-if="
                    selectedItem.blacklist ||
                    selectedItem.resigned ||
                    (selectedItem.clientSuspends && selectedItem.clientSuspends.length > 0)
                  "
                  class="rounded-lg red lighten-5 red--text ml-2 mt-2"
                  >Status :
                  {{
                    (selectedItem.blacklist && 'Blacklisted') ||
                    (selectedItem.resigned && 'Resign') ||
                    (selectedItem.clientSuspends && selectedItem.clientSuspends.length > 0 && 'Suspend')
                  }}</v-chip
                >
              </v-col>
              <v-col cols="10" class="background rounded-lg my-2">
                <atoms-text class="font-weight-bold text-left primary--text">Alamat</atoms-text>
                <atoms-text class="text-left"
                  ><span class="font-weight-bold">Provinsi: </span
                  >{{
                    selectedItem.address && selectedItem.address.province
                      ? selectedItem.address.province
                      : 'Tidak dicantumkan'
                  }}</atoms-text
                >
                <atoms-text class="text-left"
                  ><span class="font-weight-bold">Kota: </span
                  >{{
                    selectedItem.address && selectedItem.address.city ? selectedItem.address.city : 'Tidak dicantumkan'
                  }}</atoms-text
                >
              </v-col>
              <v-col cols="10" class="background rounded-lg my-2">
                <atoms-text class="font-weight-bold text-left primary--text">Tentang Diri</atoms-text>
                <atoms-text class="text-left"
                  ><span class="font-weight-bold">Deskripsi: </span
                  >{{ selectedItem.about ? selectedItem.about : 'Tidak dicantumkan' }}</atoms-text
                >
                <atoms-text class="text-left" v-if="selectedItem.detail && selectedItem.detail.skills"
                  ><span class="font-weight-bold">Kemampuan: {{ (!selectedItem.detail.skills && '-') || '' }}</span>
                </atoms-text>
                <ul v-if="selectedItem.detail.skills" class="text-left">
                  <li v-for="(x, i) in selectedItem.detail.skills" :key="i">
                    {{ x.name || '-' }} - {{ x.duration || '-' }}
                  </li>
                </ul>
                <atoms-text class="text-left" v-if="selectedItem.detail && selectedItem.detail.experiences"
                  ><span class="font-weight-bold"
                    >Pengalaman: {{ (!selectedItem.detail.experiences && '-') || '' }}</span
                  >
                </atoms-text>
                <ul v-if="selectedItem.detail.experiences" class="text-left">
                  <li v-for="(x, i) in selectedItem.detail.experiences" :key="i">
                    <span v-if="x.dateStart">
                      {{ x.experience || 'Tidak dicantumkan' }} (<span class="primary--text">{{
                        $moment(x.dateStart).format('DD-MM-YYYY')
                      }}</span
                      >-{{ $moment(x.dateEnd).format('DD-MM-YYYY') }})
                    </span>
                  </li>
                </ul>
              </v-col>
              <v-col cols="10" class="px-0">
                <atoms-button disabled :style="{ width: '100%' }" class="primary mt-2">Hubungi</atoms-button>
                <atoms-button
                  @click="isSelectedSuspend ? unSuspendWorker() : (showSuspend = true)"
                  :style="{ width: '100%' }"
                  class="primary mt-2"
                  ><v-icon left>mdi-account-{{ isSelectedSuspend ? 'check' : 'cancel' }}</v-icon
                  >{{ isSelectedSuspend ? 'Unsuspend' : 'Suspend' }}</atoms-button
                >
              </v-col>
            </v-row>
          </v-col>
          <v-col md="8">
            <v-container>
              <v-row align="center">
                <v-col cols="12" md="8">
                  <atoms-text
                    ><v-icon left>mdi-history</v-icon>
                    <span class="font-weight-medium">Riwayat</span>
                    <strong> {{ $toCapitalize(selectedItem.fullName) }}</strong></atoms-text
                  >
                </v-col>
                <v-col cols="12" md="4" align="center">
                  <v-tabs
                    background-color="background"
                    color="primary"
                    height="40"
                    v-model="tabs"
                    hide-slider
                    :show-arrows="$vuetify.breakpoint.smAndDown ? true : false"
                    :center-active="$vuetify.breakpoint.smAndDown ? true : false"
                    class="rounded-lg d-flex justify-center justify-md-end"
                  >
                    <v-tab
                      v-for="i in ['absensi', 'summary']"
                      :key="i"
                      class="text-capitalize ma-1"
                      active-class="primary lighten-5 primary--text rounded-lg "
                    >
                      <atoms-text class="font-weight-medium">{{ i }}</atoms-text>
                    </v-tab>
                  </v-tabs>
                </v-col>
              </v-row>
            </v-container>
            <!-- absensi -->
            <v-fade-transition>
              <v-container v-if="tabs === 0">
                <v-row justify="space-between">
                  <v-col cols="12" md="6">
                    <v-dialog ref="pickerFromDialog" v-model="from.modal" :return-value.sync="from.value" width="290px">
                      <template v-slot:activator="{ on, attrs }">
                        <atoms-text-field label="Dari Tanggal">
                          <v-text-field
                            :loading="mainLoading"
                            class="rounded-lg"
                            placeholder="Dari Tanggal"
                            append-icon="mdi-calendar"
                            background-color="background"
                            v-model="formatFromDate"
                            v-bind="attrs"
                            v-on="on"
                            hide-details
                            readonly
                            solo
                            flat
                          />
                        </atoms-text-field>
                      </template>
                      <v-date-picker
                        :max="$moment().endOf('month').format('YYYY-MM-DD')"
                        v-model="from.value"
                        scrollable
                        class="rounded-lg"
                        @click:date="$refs.pickerFromDialog.save(from.value)"
                      />
                    </v-dialog>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-dialog ref="pickerToDialog" v-model="to.modal" :return-value.sync="to.value" width="290px">
                      <template v-slot:activator="{ on, attrs }">
                        <atoms-text-field label="Sampai Tanggal">
                          <v-text-field
                            class="rounded-lg"
                            placeholder="Sampai Tanggal"
                            append-icon="mdi-calendar"
                            background-color="background"
                            v-model="formatToDate"
                            :loading="mainLoading"
                            v-bind="attrs"
                            v-on="on"
                            hide-details
                            readonly
                            solo
                            flat
                          />
                        </atoms-text-field>
                      </template>
                      <v-date-picker
                        :max="$moment().endOf('month').format('YYYY-MM-DD')"
                        v-model="to.value"
                        scrollable
                        class="rounded-lg"
                        @click:date="$refs.pickerToDialog.save(to.value)"
                      />
                    </v-dialog>
                  </v-col>
                  <v-col cols="12" md="auto">
                    <atoms-button
                      class="primary"
                      style="width: 100%"
                      @click="
                        () => {
                          sort = sort === constant[0] ? constant[1] : constant[0]
                          searchItems(selectedItem._id)
                        }
                      "
                      ><v-icon left>mdi-filter-variant-{{ sort === constant[0] ? 'minus' : 'plus' }}</v-icon
                      >Sort: {{ sort }}</atoms-button
                    >
                  </v-col>
                  <v-col cols="12" md="auto">
                    <v-combobox
                      class="rounded-lg"
                      placeholder="Filters"
                      prepend-inner-icon="mdi-filter-variant"
                      hide-details
                      multiple
                      :items="filters.map((x) => x.title)"
                      @change="searchItems(selectedItem._id)"
                      dense
                      solo
                      flat
                      required
                      :style="{
                        'box-shadow': 'rgba(0, 0, 0, 0.15) 0px 0px 0px 1px',
                      }"
                  /></v-col>
                </v-row>
                <div v-if="mainLoading" class="mt-8">
                  <v-skeleton-loader
                    v-for="i in $store.state.attendance.limit || 5"
                    :key="i"
                    type="list-item-avatar-three-line"
                    :class="['accent', 'rounded-lg mb-2']"
                    :style="{
                      'box-shadow': 'rgba(0, 0, 0, 0.15) 0px 1px 4px',
                    }"
                  />
                </div>
                <div v-else>
                  <div v-if="$store.state.attendance.data && $store.state.attendance.data.length > 0" class="mt-8">
                    <div
                      v-for="(
                        { absensiCompleted, supervisorApproval, location, phlJamMulai, area, ...rest }, i
                      ) in $store.state.attendance.data.filter((x) => x.locationId) || []"
                      :key="i"
                      :class="['accent', 'rounded-lg pa-4 mb-2']"
                      :style="{
                        'box-shadow': 'rgba(0, 0, 0, 0.15) 0px 1px 4px',
                      }"
                    >
                      <v-row align="center">
                        <v-col class="shrink">
                          <v-icon>mdi-map-marker</v-icon>
                        </v-col>
                        <v-col cols="8" md="4">
                          <atoms-text class="font-weight-bold">{{
                            $moment(phlJamMulai).format('DD MMMM YYYY')
                          }}</atoms-text>

                          <atoms-text class="primary--text"
                            >Area {{ (area && area.title) || '-' }}, Lokasi
                            {{ (location && location.name) || '-' }}</atoms-text
                          >
                        </v-col>
                        <v-col align="start" cols="12" md="auto">
                          <atoms-text class="font-weight-bold"
                            >Status: {{ supervisorApproval ? supervisorApproval : 'Proses' }}</atoms-text
                          >
                          <atoms-text class="primary--text"
                            >Absensi : {{ absensiCompleted ? 'Lengkap' : 'Belum Lengkap' }}</atoms-text
                          >
                        </v-col>
                        <v-col :align="$vuetify.breakpoint.smAndDown ? 'start' : 'end'">
                          <atoms-text
                            ><strong>Masuk:</strong> Pukul {{ $moment(phlJamMulai).format('H:mm:ss') }}</atoms-text
                          >
                        </v-col>
                        <v-col class="shrink">
                          <atoms-button
                            @click="
                              showDetail = {
                                absensiCompleted,
                                supervisorApproval,
                                location,
                                phlJamMulai,
                                area,
                                ...rest,
                              }
                            "
                            >Detail</atoms-button
                          >
                        </v-col>
                      </v-row>
                    </div>
                    <v-row v-if="!reportLoading" align="center" justify="center">
                      <v-col align="center">
                        <v-container class="max-width">
                          <v-pagination
                            v-model="pageItems"
                            :length="
                              Math.ceil($store.state.attendance.length / (limit || $store.state.attendance.limit))
                            "
                          ></v-pagination>
                        </v-container>
                      </v-col>
                    </v-row>
                  </div>
                  <molecules-wrapper v-else class="d-flex justify-center align-center my-8 py-8 outlined">
                    <div class="text-center">
                      <atoms-image :width="250" src="/assets/missing.svg" />
                      <atoms-title :h3="true" class="mt-6">Tidak Ada Riwayat Ditemukan</atoms-title>
                    </div>
                  </molecules-wrapper>
                </div>
              </v-container>
            </v-fade-transition>
            <!-- report -->
            <v-fade-transition>
              <v-container v-if="tabs === 1">
                <molecules-wrapper class="d-flex justify-center align-center pb-8 px-0 outlined">
                  <v-row>
                    <v-col cols="12">
                      <molecules-summary-card
                        background="background"
                        icon="account-details"
                        :title="`${$store.state.attendance.length} 
                  Absensi`"
                        :desc="$moment().format('DD MMMM YYYY')"
                        :loading="reportLoading"
                      />
                    </v-col>
                    <v-col cols="12">
                      <molecules-summary-card
                        background="background"
                        icon="account-check"
                        :title="`${$store.state.attendance.complete} 
                  Hadir`"
                        :desc="$moment().format('DD MMMM YYYY')"
                        :loading="reportLoading"
                      />
                    </v-col>
                    <v-col cols="12">
                      <molecules-summary-card
                        background="background"
                        icon="account-check"
                        :title="`${$store.state.attendance.approved}
                  Approved`"
                        color="green"
                        :desc="$moment().format('DD MMMM YYYY')"
                        :loading="reportLoading"
                      />
                    </v-col>
                  </v-row>
                </molecules-wrapper>
              </v-container>
            </v-fade-transition>
          </v-col>
        </v-row>
      </v-scroll-y-transition>
    </div>
    <v-slide-x-reverse-transition>
      <organism-attendance-detail
        v-if="showDetail"
        @closed="
          () => {
            closeAllDialogAndReset()
            showDetail = null
          }
        "
        @toggleApprove="(e) => (showApprove = e)"
        :data="showDetail"
      />
    </v-slide-x-reverse-transition>
  </div>
  <div v-else>
    <atoms-text
      >Akses Halaman di
      <a href="https://hayosystems-v3.web.app/attendance/history"
        >https://hayosystems-v3.web.app/attendance/history</a
      ></atoms-text
    >
  </div>
</template>
<script>
export default {
  data() {
    return {
      data: null,
      client: null,
      showSuspend: false,
      showApprove: null,
      showDetail: null,
      reasonSuspend: '',
      show: false,
      comment: '',
      term: '',
      tabs: null,
      items: null,
      selectedItem: null,
      page: 1,
      pageItems: 1,
      limit: 5,
      mainLoading: false,
      reportLoading: false,
      from: {
        modal: false,
        value: this.$moment().startOf('month').format('YYYY-MM-DD'),
      },
      to: {
        modal: false,
        value: this.$moment().format('YYYY-MM-DD'),
      },
      sort: '',
      constant: ['Desc', 'Asc'],
      filter: null,
      filters: [
        {
          title: this.$status.proses,
          value: {
            absensiCompleted: true,
            supervisorApproval: { $ne: true },
          },
        },
        {
          title: this.$status.belum,
          value: {
            absensiCompleted: false,
            supervisorApproval: { $ne: true },
          },
        },
        {
          title: this.$status.approve,
          value: {
            absensiCompleted: true,
            supervisorApproval: this.$status.approve,
          },
        },
        {
          title: this.$status.reject,
          value: {
            absensiCompleted: true,
            supervisorApproval: this.$status.reject,
          },
        },
        {
          title: this.$status.semua,
          value: {
            absensiCompleted: undefined,
            supervisorApproval: undefined,
          },
        },
      ],
    }
  },
  computed: {
    formatFromDate: {
      set(value) {
        this.from.value = this.$moment(value).format('YYYY-MM-DD')
      },
      get() {
        return this.$moment(this.from.value).format('DD MMMM YYYY')
      },
    },
    formatToDate: {
      set(value) {
        this.to.value = this.$moment(value).format('YYYY-MM-DD')
      },
      get() {
        return this.$moment(this.to.value).format('DD MMMM YYYY')
      },
    },
    isSelectedSuspend() {
      return Boolean(this.selectedItem?.clientSuspends?.length > 0 || false)
    },
  },
  watch: {
    page: {
      handler() {
        this.search()
      },
      deep: true,
    },
    tabs: {
      handler() {
        if (Number(this.tabs) && this.tabs === 1) {
          this.getSummary(this.selectedItem?._id, false)
        } else {
          this.pageItems = 1
          this.searchItems(this.selectedItem?._id)
        }
      },
      deep: true,
    },
    pageItems: {
      handler() {
        this.searchItems(this.selectedItem?._id)
      },
      deep: true,
    },
    'from.value': function (newValue) {
      if (newValue) {
        this.pageItems = 1
        this.searchItems(this.selectedItem?._id)
      }
    },
    'to.value': function (newValue) {
      if (newValue) {
        this.pageItems = 1
        this.searchItems(this.selectedItem?._id)
      }
    },
  },
  async mounted() {
    return
    if (this.$route.query?.search) {
      this.$store.commit('SET', { loading: true })
      const result = await this.$store.dispatch('worker/get', {
        query: { _id: this.$route.query?.search },
      })
      result && this.selectItem(result)

      this.$store.commit('SET', { loading: false })
    } else {
      this.search()
    }
  },
  methods: {
    openDetail(payload) {
      this.show = true
      this.data = payload
    },
    async closeAllDialogAndReset() {
      this.show = false
      this.approve = false
      this.searchItems(this.selectedItem?._id)
    },
    async suspendWorker(reason) {
      if (!this.selectedItem) return Promise.reject('Kesalahan Data Pekerja')
      this.$store.commit('SET', { loading: true })
      await this.$store
        .dispatch('worker/suspend', {
          ...this.selectedItem,
          reason,
        })
        .then((result) => {
          this.selectItem(result)
          this.showSuspend = false
          this.$showDialog({
            title: 'Berhasil',
            body: `${this.selectedItem?.fullName || 'Pekerja'} telah disuspend.`,
          })
        })

      this.$store.commit('SET', { loading: false })
    },
    async unSuspendWorker() {
      if (!this.selectedItem) return Promise.reject('Kesalahan Data Pekerja')
      this.$store.commit('SET', { loading: true })
      await this.$store
        .dispatch('worker/unsuspend', {
          ...this.selectedItem,
        })
        .then((result) => {
          this.selectItem(result)
          this.$showDialog({
            title: 'Berhasil',
            body: `${this.selectedItem?.fullName || 'Pekerja'} telah diunsuspend.`,
          })
        })
      this.$store.commit('SET', { loading: false })
    },
    async toggleApproval() {
      this.$store.commit('SET', { loading: true })
      try {
        const data = {
          ...this.showApprove,
          supervisorId: this.$store.state.user?._id,
          supervisorName: this.$store.state.user?.email,
          supervisorComment: this.comment
            ? this.comment
            : 'OK - ' + this.showApprove?.supervisorApproval == this.$status.approve
            ? 'Approve'
            : 'Reject',
          supervisorTimeStamp: new Date(),
          worker: undefined,
          detail: undefined,
          client: undefined,
        }
        await this.$store
          .dispatch('attendance/setStatus', data)
          .then(() => {
            this.$store.commit('dialog/show', {
              title: 'Berhasil',
              body: `Pekerja atas nama ${this.$toCapitalize(this.showApprove?.worker?.fullName || '-')} berhasil ${
                (this.showApprove?.supervisorApproval !== this.$status.approve && 'reject') || ''
              } approval`,
            })
          })
          .catch(() => {
            this.$store.commit('dialog/show', {
              title: 'Gagal',
              body: `Pekerja atas nama ${this.$toCapitalize(this.showApprove?.worker?.fullName || '-')} gagal ${
                (this.showApprove?.supervisorApproval !== this.$status.approve && 'reject') || ''
              } approval`,
            })
          })
      } finally {
        this.showDetail = null
        this.showApprove = null
        this.comment = ''
        this.selectItem(this.selectedItem)
        this.$store.commit('SET', { loading: false })
      }
    },
    async search(payload = '') {
      this.mainLoading = true
      this.selectedItem = null
      try {
        const { result, ...rest } = await this.$store.dispatch('attendance/getUniq', {
          term: this.term || '',
          page: this.page,
          limit: this.limit,
        })
        this.items = {
          data: result,
          ...rest,
        }
      } finally {
        this.mainLoading = false
      }
    },

    async selectItem(payload) {
      this.pageItems = 1
      this.filter = [this.$status.semua]
      this.sort = this.constant[0]
      this.selectedItem = payload
      if (this.$route.query?.client) {
        this.client = (
          await this.$store.dispatch('client/getAll', {
            query: {
              _id: this.$route?.query?.client,
            },
            page: 1,
            limit: 1,
          })
        )?.[0]
        this.searchItems(payload._id, this.$route.query?.client)
      } else {
        this.searchItems(payload._id)
      }
    },
    async getSummary(payload, filter = true) {
      this.reportLoading = true
      await this.$store.dispatch('attendance/getSummary', {
        query: {
          ktpPhl: payload,
          // locationId: { $exists: true },
          phlJamMulai: filter
            ? {
                $gte: new Date(this.$moment(this.from.value).toDate().setHours(0, 0, 0, 0)),
                $lte: new Date(this.$moment(this.to.value).toDate().setHours(24, 0, 0, 0)),
              }
            : { $exists: true },
        },
      })
      this.reportLoading = false
    },
    async searchItems(workerId, clientId) {
      this.mainLoading = true
      try {
        // this.getSummary(workerId)
        await this.$store.dispatch('attendance/getAll', {
          query: {
            clientId,
            ktpPhl: workerId,
            // locationId: { $exists: true },
            phlJamMulai: {
              $gte: new Date(this.$moment(this.from.value).toDate().setHours(0, 0, 0, 0)),
              $lte: new Date(this.$moment(this.to.value).toDate().setHours(24, 0, 0, 0)),
            },
            ...(this.filter && this.filter.length > 0
              ? Object.assign({}, ...this.filter?.map((x) => this.filters?.find((y) => y.title === x)?.value))
              : []),
          },
          custom: {
            pipeline: [
              {
                $sort: {
                  _createdDate: this.sort === this.constant[0] ? -1 : 1,
                },
              },
              {
                $lookup: {
                  from: 'Clients',
                  localField: 'clientId',
                  foreignField: '_id',
                  as: 'client',
                },
              },
              {
                $unwind: {
                  path: '$client',
                  preserveNullAndEmptyArrays: true,
                },
              },
              {
                $lookup: {
                  from: 'Workers',
                  localField: 'ktpPhl',
                  foreignField: '_id',
                  as: 'worker',
                },
              },
              {
                $unwind: {
                  path: '$worker',
                  preserveNullAndEmptyArrays: true,
                },
              },
              // {
              //   $unwind: {
              //     path: "$detail",
              //     preserveNullAndEmptyArrays: true,
              //   },
              // },
              {
                $lookup: {
                  from: 'Areas',
                  localField: 'areaId',
                  foreignField: '_id',
                  as: 'area',
                },
              },
              {
                $unwind: {
                  path: '$area',
                  preserveNullAndEmptyArrays: true,
                },
              },
            ],
          },
          page: this.pageItems,
          limit: this.limit,
        })
      } finally {
        this.mainLoading = false
      }
    },
  },
}
</script>
